/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Divider, SeparateLine, SeparateLineWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Služby a jiné"}>
        <SiteHeader />

        <Column className="css-1ijhuvf --style2 --full" anim={""} name={"uvod"} animS={"3"} border={""} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" anim={null} style={{"maxWidth":1390,"backgroundColor":"rgba(64,30,232,1)"}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center mb--20 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"5"} style={{"maxWidth":1425}}>
              
              <Title className="title-box fs--102 w--500 title-box--invert lh--1 mt--10" style={{"maxWidth":1106}} content={"Služby a jiné"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"Obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(38, 140, 243);\">Cambridge exams center&nbsp;</span>"}>
              </Title>

              <Text className="text-box" content={"Vyzvednutí certifikátu je možné pouze v pondělí 10:00-15:00 po předchozí domluvě.<br>Tel.: 604 286 141<br>Email.: zkousky@cambridgecenter.cz<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.cambridgecenter.cz/"} href={"https://www.cambridgecenter.cz/"} content={"Cambridge"}>
              </Button>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"v14k10td3vq"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: rgb(32, 137, 242);\">Psí salon Modrý pavilon&nbsp;</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"Tel.: 776 190 445<br>&nbsp; &nbsp; &nbsp; 775 577 647<br>Email.: info@psisluzbymaja.cz<br>"}>
              </Text>

              <Button className="btn-box" url={"http://www.psisluzbymaja.cz/"} href={"http://www.psisluzbymaja.cz/"} content={"Psí salon&nbsp;"}>
              </Button>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"Obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(42, 144, 245);\">Pojišťovna Benefit</span>"}>
              </Title>

              <Text className="text-box" content={"Otevírací doba je dle domluvy s klienty<br>Eva Třísková<br>Tel.: 722 767 676<br>Jan Blažek<br>Tel.: 722 006 208<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.benefitas.cz/"} href={"https://www.benefitas.cz/"} content={"Benefit"}>
              </Button>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"Obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(29, 133, 238);\">Tatiana Kadeřnický salón&nbsp;</span>"}>
              </Title>

              <Text className="text-box" content={"Otevírací doba:<br>Po-Pá : 8:30-19<br>So : 8:30-15<br>Ne : dle dohody<br>Tel.: 775 194 956<br>Email.: vovcencoion@email.cz<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.facebook.com/kadernictviTatiana/"} href={"https://www.facebook.com/kadernictviTatiana/"} content={"Kadeřnictví&nbsp;"}>
              </Button>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"Obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(37, 139, 241);\">Oprava obuvi</span>"}>
              </Title>

              <Text className="text-box" content={"Dále i čalounické a brašnářské práce<br>Otevírací doba:<br>Po-Pá : 9-19<br>So : 10-14<br>Polední pauza 12:30-13:30<br>Tel.: 770 643 217<br>Email.: vasylrychleopravy@seznam.cz<br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"Obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(39, 137, 236);\">ProfiNails</span>"}>
              </Title>

              <Text className="text-box" content={"Nabízíme : Manikúru, pedikúru, prodlužování řas, kosmetickou péči, depilaci<br>Otevírací doba:<br>Po-Pá : 8:30-19<br>So : 8:30-15<br>Ne : pouze na objednání<br>Tel.: 608 682 468<br>"}>
              </Text>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"im5n1mm00x"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: rgb(34, 138, 243);\">Sunny Dreams Solárium&nbsp;</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"Otevírací doba:<br>Po : 8-20<br>&nbsp;Út -Čt : 12-20<br>Pá 8-20<br>Ne : 12-20<br>Tel.: 775 342 570<br>Email.: krc@sunnydreams.cz<br>"}>
              </Text>

              <Button className="btn-box" url={"https://solarium.sunnydreams.cz/index.php"} href={"https://solarium.sunnydreams.cz/index.php"} content={"Solárium&nbsp;"}>
              </Button>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"Obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(34, 136, 239);\">Městská knihovna&nbsp;</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"7y75243mac"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--30 pt--30" name={"Obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(32, 137, 243);\">Městská policie</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"02ip2hm2oya6"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"vb02ua9x88"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: rgb(21, 133, 246);\">Atrium Modrý pavilon</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"Atrium je zastřešený prostor uprostřed mezi kancelářemi. Nachází se zde dva záhony a nádoby s květinami. Na posezení můžete zvolit z několika možností - lavičky kolem již zmíněných záhonů, nebo křesílka a židličky u stolečků."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"kt7uz6oh07f"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"rn78hrm7iai"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: rgb(20, 131, 243);\">Sál Modrý pavilon</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"Sál modrého pavilonu má cca 80 míst k sezení a je možnost si v něm rezervovat čas pro pořádaní přednášek, schůzí i výstav."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"svlutsjd2xg"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--60 pt--60" name={"rpueakg17d9"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: rgb(24, 134, 245);\">Komunitní makléřka Zuzana Vránová</span>"}>
              </Title>

              <Button className="btn-box" url={"https://www.facebook.com/zuzanavranova.maklerka"} href={"https://www.facebook.com/zuzanavranova.maklerka"} content={"Facebook"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"5qnh7pyz0b8"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: rgb(24, 134, 243);\">Ateliér Unlimited Freedom</span>"}>
              </Title>

              <Button className="btn-box" url={"https://www.facebook.com/RitchieUnlimited"} href={"https://www.facebook.com/RitchieUnlimited"} content={"Facebook&nbsp;"}>
              </Button>

              <Button className="btn-box" url={"https://www.posvim.cz/unlimited-freedom/"} href={"https://www.posvim.cz/unlimited-freedom/"} content={"Merch<br>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"rklk7jptetd"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: rgb(44, 139, 235);\">Alzabox&nbsp;</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"11laibtmnkbl"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center pb--80 pt--80" name={"8eevdo06k7a"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: rgb(38, 139, 240);\">Ppl box</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"paticka"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box" content={"Modrý pavilon"}>
              </Title>

              <Text className="text-box" content={"+420 739 090 844<br>ocmodrypavilon@seznam.cz<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}